.about-section{
flex:1;
width:100%;
height:100%;
padding: 6rem 2rem 0;
}
.app-profiles{
display: flex;
flex-wrap: wrap;
width:100%;
height: 100%;
flex-direction: row;
justify-content: space-evenly;
align-content:flex-start ;
}

.app-profile-item{
display: flex;
width:20%;
min-height: 25vw;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
margin:1rem 2rem
}
.app-profile-item img{
width:100%;
border-radius: 15px;
object-fit: cover;
}

@media only screen and (min-width:2000px)
{
    .app-profile-item{
    width:20%;
    }
}

@media only screen and (max-width:1200px)
{
    .app-profile-item{
    width:40%;
    }
}


@media only screen and (max-width:600px)
{
    .app-profile-item{
    width:80%;
    }
}