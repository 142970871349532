.socialmedia_icons{
width: 10%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-left:5% ;
}

.icon{
display: flex;
justify-content: center;
align-items: center;
width:30px;
height:30px;
border-radius: 50%;
background-color: gainsboro;
margin-bottom:10px;
/* padding-top:2px; */ 
}

.here{
width:15px;
height:15px;
padding:7px;
}

