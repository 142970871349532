.app-Navbar{
width:100%;
height:auto;
display:flex;
justify-content: space-between;
align-items: center;
/* padding:1rem 2rem; */
background-color: rgba(245, 245, 245, 0.306);
backdrop-filter: blur(4px);
-webkit-backdrop-filter: blur(4px);
position:fixed;
z-index: 2;
margin:0;
padding:0;
}

.app-Navbar-links{
flex:1;
display:flex;
justify-content: center;
align-items: center;
list-style: none;
}       

a{
text-decoration: none;
padding: 0 2rem;
cursor: pointer;
text-transform: uppercase;
font-weight:500;
color: rgb(84, 80, 80);
transition: all 0.3s ease-in-out;
}

a:hover{
color: blue;
}
@keyframes app-logo-spin {
    from {
      transform: rotate(-15deg);
    }
    to {
      transform: rotate(15deg);
    }
  }

@media (prefers-reduced-motion: no-preference) {
    .app-Navbar-logo {
    animation: app-logo-spin infinite 5s linear;
    width:15%;
    height:15%;
    }
  }
 
@media only screen and (max-width:900px)
    {
        .app-Navbar-links{
            display: none;
            }
    
    }

.app-Navbar-menu{
width:35px;
height:35px;
border-radius: 50%;
position: relative;
display: flex;
justify-content: center;
align-items: center;
background-color: rgb(43, 43, 193);
}
.app-Navbar-menu #start{
width:70%;
height:70%;
color:white;
}
    
.app-Navbar-menu ul{
list-style: none;
margin:0;
padding:0;
height:100%;
width:100%;
display:flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
}  

.app-Navbar-menu ul li{
margin:2rem;
}  
    

.app-Navbar-menu #motion-frame{
position: fixed;
top:0;
right:0;
z-index:5;
padding:1rem;
width:60%;
height:100vh;
background:url('../../assets/background-mobilemenu.jpg') no-repeat;
background-size: cover;
/* background-repeat: no-repeat; */
box-shadow: 5px 5px 5px 5px rgb(235, 94, 118); 
}

.app-Navbar-menu a{
margin:0 5%;
}

.app-Navbar-menu #close{
width:35px;
height:35px;
color:blue;
float:right;
}

@media only screen and (min-width:900px)
{
    .app-Navbar-menu{
        display: none;
        }

}