.skills-section{
flex:1;
width:100%;
height:100%;
padding: 6rem 2rem 0;
}

.app-skills{
display: flex;
width:100%;
justify-content: space-evenly;
align-items: center;
font-size: 1rem;
flex-wrap: wrap;
margin:5% 0;
}

@media only screen and(min-width:901px){
    .app-skill-item{
        width:25%;
    }
}

@media only screen and(max-width:900px){
    .app-skill-item{
        width:30%;
    }
}

.app-skill-item{
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
/* width:20%; */
margin:10px;
font-size: 1rem;
}

.app-skill-container{
width:5vw;
height:5vw;
border-radius:50% ;
display: flex;
justify-content: center;
align-items: center;
box-shadow: 0 0 5px palevioletred;
}
.app-skill-item img{
width:80%
}

.downloadResume{
border: 1px solid blue;
padding:20px 10px;
border-radius: 5px;
}

.downloadResume:hover{
cursor: pointer;
color:white;
background-color: blue;
}