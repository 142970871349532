.contact-section{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width:100%;
height:100%;
padding: 6rem 2rem 0;
}
.app-footer-cards{
display: flex;
width:40%;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
margin: 2rem 1rem 1rem;
}

.app-footer-card{
display: flex;
justify-content: flex-start;
width: auto;
align-items: center;
margin:1rem 0;
padding: 1rem;
border-radius: 10px;
cursor: pointer;
background-color: rgb(245, 212, 212);
transition: all 0.3s ease-in-out;
}

.app-footer-card img{
width:15%;
}

.app-footer-card a{
text-transform: none;
text-decoration: none;
}

.app-footer-card:hover{
box-shadow: 0 0 25px rgb(215, 213, 213);
}
.app-footer-card a:hover{
color: yellowgreen;
}
    
.app-footer-card:first-child{
background-color: rgb(217, 244, 235);
}

.app-footer-form{
width:60%;
flex-direction: column;
margin:1rem 2rem;
}

.app-footer-form div{
width:100%;
margin: 1rem 0;
border-radius:5px ;
cursor: pointer;
/* background-color: rgb(229, 221, 221); */
transition: all 0.3s ease-in-out;
}
.app-footer-form input,textarea{
width: 100%;
padding: 0.95rem;
border: none;
border-radius: 7px;
background-color:rgb(235, 230, 230);
color: blue;
outline: none;
}
.app-footer-form textarea{
height:200px;
}

.app-footer-form textarea:hover{
box-shadow: 0 0 25px rgb(235, 230, 230);
}

.app-footer-form button{
padding: 1rem 2rem;
border-radius: 10px;
border: none;
background-color: blue;
color: white;
font-weight: 500;
outline: none;
cursor: pointer;
}