.app-header{
display: flex;
flex-direction: row;
width:100%;
padding: 8rem 2rem 0;
background:url(../../assets/background-header.jpg) no-repeat ;
background-size: cover;
border-radius: 10px;
justify-content: flex-start;
align-items: center;
}

@media only screen and (min-width:2000px){
    .app-header{
        display: flex;
        flex-direction: row;
    }
}

@media only screen and (max-width:1200px){
    .app-header{
        display: flex;
        flex-direction: column; 
    }
}


.app-header-info{
display: flex;
width:30%;
height:100%;
flex-direction: column;
justify-content: flex-end;
align-items: flex-end;
}

.app-header-badge{
display: flex;
width:100%;
flex-direction: column;
justify-content: flex-end;
align-items: flex-end;
}

.badge-component,.tag-component{
padding: 1rem 2rem;
background: white;
box-shadow:0 0 20px black;
border-radius: 15px;
display: flex;
width:auto;
justify-content: flex-end;
align-items: center;
}

.tag-component{
flex-direction: column;
margin-top:2rem
}
.badge-component p{
font-size:1.5rem;
padding: 5px;
margin: 0;
}
.badge-component h1{
font-size:2rem;
padding: 5px;
margin:0;
}

.tag-component p{
width:100%;
text-transform: uppercase;
text-align: right;
color:grey;
padding:5px;
margin: 0;
font-size: 1rem;
font-weight: 500;
}

@media only screen and (max-width:1200px){
    .app-header-info{
    width: 100%;
    margin-left: 0;
    }
}

@media only screen and(max-width:900px) {
    .app-header-info{
    width: 100%;
    margin-left: 0;
    }
    .badge-component p{
    font-size:0.75rem;
    }
    .badge-component h1{
    font-size:1rem;
    }
    .tag-component p{
    font-size: 0.5rem;
    }
}


.app-header-image{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}

.app-header-image img{
width:470px;
height:500px;
object-fit: contain;
margin-left:5rem;
}
.app-header-circles{
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: flex-start;
margin-left: 1rem;
}

.circle-component{
display: flex;
justify-content: center;
align-items: center;
width:5vw;
height:5vw;
border-radius: 50%;
background: white;
box-shadow: 0 0 10px blue;
}

.circle-component img{
width:80%;
height:80%;
}

.circle-component:nth-child(1){
width:4vw;
height:4vw;
margin:1rem 0 1rem 2rem ;    
}

.circle-component:nth-child(2){
width:5vw;
height:5vw;
margin:1rem 0 1rem 5rem; 
}
.circle-component:nth-child(3){
width:6vw;
height:6vw;
margin:1rem 0 1rem 8rem;  
}
.circle-component:nth-child(4){
width:5vw;
height:5vw;
margin:1rem 0 1rem 5rem;      
}
.circle-component:nth-child(5){
width:4vw;
height:4vw;
margin:1rem 0 1rem 2rem;   
}

@media screen and (max-width:1200px){
    .app-header-circles{
    display: flex;
    flex-direction: row;
    }
    
    .circle-component:nth-child(1){
    width:8vw;
    height:8vw;
    margin:1rem 1rem 0 1rem ;    
    }
    
    .circle-component:nth-child(2){
    width:9vw;
    height:9vw;
    margin:2rem 1rem 0 1rem; 
    }
    .circle-component:nth-child(3){
    width:11vw;
    height:11vw;
    margin:3rem 1rem 0 1rem;  
    }
    .circle-component:nth-child(4){
    width:9vw;
    height:9vw;
    margin:2rem 1rem 0 1rem;      
    }
    .circle-component:nth-child(5){
    width:8vw;
    height:8vw;
    margin:1rem 1rem 0 1rem;   
    } 
        
}


/* @media screen and(max-width:800px) {
    .app-header-circles{
    display: flex;
    flex-direction: row;
    }
    .circle-component:nth-child(1){
    width:10vw;
    height:10vw;
    margin:10px 5px 0 5px ;    
    }
    
    .circle-component:nth-child(2){
    width:11vw;
    height:11vw;
    margin:10px 5px 0 5px; 
    }
    .circle-component:nth-child(3){
    width:12vw;
    height:12vw;
    margin:10px 5px 0 5px;  
    }
    .circle-component:nth-child(4){
    width:11vw;
    height:11vw;
    margin:10px 5px 0 5px;      
    }
    .circle-component:nth-child(5){
    width:10vw;
    height:10vw;
    margin:10px 5px 0 5px;   
    }  
} */


